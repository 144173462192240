<template>
  <div class="row">
    <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
      <div class="card border-0 shadow-sm overflow-hidden">
        <div class="card-body">
          <span v-if="!editingTitle" @click="editingTitle = true">
            <svg viewBox="0 0 512 512" height="10">
              <path
                d="M357 15c12-13 32-15 46-3l50 42c14 11 15 32 4 45L171 440 71 357zM57 373l100 84S54 512 37 498s20-125 20-125z"
              />
            </svg>
            {{ flowName }}
          </span>
          <span v-else @keyup.esc="titleDone">
            <input
              id="flow-title"
              @keyup.enter="titleDone"
              @blur="titleDone"
              type="text"
              class="form-control"
              v-model="flowName"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlowHeader",
  props: {
    title: {
      type: String,
      default: "Untitled flow",
    },
  },
  data: function() {
    return {
      editingTitle: false,
      flowName: null,
    };
  },
  methods: {
    titleDone() {
      this.editingTitle = false;
      this.$emit("saveTitle", this.flowName);
    },
  },
  mounted() {
    this.flowName = this.title;
  },
};
</script>
