<template>
  <component :is="layout">
    <div v-if="automation" class="mb-6 mt-6">
      <flow-header :title="automation.name" @saveTitle="setAutomationTitle" />
    </div>

    <Automation
      v-if="automation"
      :automation="automation"
      :loading="isLoading"
      @save="save"
    />
  </component>
</template>

<script>
import jsonAPI from "@/api";
// Flow split
import Automation from "@/components/Automation/Automation";
import Default from "@/layouts/default.vue";
import FlowHeader from "@/components/Automation/FlowHeader";
// Helpers
import { updateApi } from "@/helpers/apiConnection";

export default {
  components: {
    Default,
    FlowHeader,
    Automation,
  },
  beforeRouteEnter(to, from, next) {
    const _id = to.params.id;
    jsonAPI.get("automationRule/" + _id).then((response) => {
      const ruleObj = Object.assign({}, response.data);
      next((vm) => {
        vm.automation = ruleObj;
        vm.isLoading = false;
      });
    });
  },
  data() {
    return {
      automation: null,
      isLoading: true,
      layout: "Default",
    };
  },
  methods: {
    setAutomationTitle(name) {
      this.automation.name = name;
    },
    async save(automation) {
      this.loading = true;
      this.automation = automation;
      this.isModalVisible = true;
      const res = await updateApi(
        `/automationRule/${this.automation.id}`,
        this.automation
      ).catch((error) => {
        window.Bus.$emit("flash-message", {
          text: error.message,
          type: "error",
        });
        this.loading = false;
      });
      if (res) {
        window.Bus.$emit("flash-message", {
          text: "Automation saved",
          type: "success",
        });
        this.$router.push({ name: "automations.index" });
      }
    },
  },
};
</script>
